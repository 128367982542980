<template>
  <div class="request-details">
    <div class="max-width">
      <div class="text-center d-flex justify-space-between align-center mb-10">
        <div
          class="d-inline-flex align-center cursor-pointer"
          @click="$router.go(-1)"
        >
          <v-icon
            >mdi-chevron{{ language == "ar" ? "-right" : "-left" }}</v-icon
          >
          <span class="mx-2">{{ $t("global.backToProducts") }}</span>
        </div>
        <h2 class="font-42 arial-bold">{{ $t("global.completeRequest") }}</h2>
        <div class="px-14" v-if="!isMd"></div>
      </div>
      <v-row :class="isMd ? 'col-reverse' : ''">
        <v-col cols="12" md="7">
          <div class="request-details__content pa-5 radius-5">
            <span class="d-block font-24 font-600 mb-5">{{
              $t("global.requestDetails")
            }}</span>

            <v-row>
              <v-col cols="12" md="6">
                <base-input
                  v-model="obj.username"
                  :placeholder="$t('global.name')"
                  title=""
                ></base-input>
              </v-col>
              <v-col cols="12" md="6">
                <vue-tel-input
                  :autoFormat="false"
                  mode="international"
                  :inputOptions="{
                    placeholder: $t('global.enterPhone'),
                  }"
                  :dropdownOptions="{
                    showFlags: true,
                    showDialCodeInSelection: true,
                  }"
                  label="321321321"
                  v-model="obj.phone"
                ></vue-tel-input>
              </v-col>
              <v-col cols="12">
                <v-radio-group
                  class="mt-0"
                  v-model="obj.toAddress"
                  row
                  hide-details
                >
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                    :class="isMd ? '' : language == 'ar' ? 'pl-3' : 'pr-3'"
                  >
                    <v-radio
                      :label="$t('global.delivery')"
                      :value="1"
                    ></v-radio>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                    :class="isMd ? 'mt-4' : language == 'ar' ? 'pr-3' : 'pl-3'"
                  >
                    <v-radio :label="$t('global.pickUp')" :value="0"></v-radio>
                  </v-col>
                </v-radio-group>
              </v-col>

              <v-col cols="12" v-if="obj.toAddress == 1">
                <base-textarea
                  v-model="obj.address"
                  :placeholder="$t('global.yourAddress')"
                  :rules="[]"
                  title=""
                ></base-textarea>
              </v-col>
              <v-col cols="12">
                <base-textarea
                  v-model="obj.notes"
                  :placeholder="$t('global.note')"
                  :rules="[]"
                  title=""
                ></base-textarea>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex justify-end mt-4">
            <v-btn
              color="#48c350"
              height="50px"
              class="px-10 d-flex align-center justify-center"
              elevation="0"
              :block="isMd"
              @click="sendRequest"
              :disabled="settings[8].value != '0' ? cartList.length == 0 : true"
            >
              <img
                width="30px"
                class="filter-white"
                :src="require('@/assets/images/icons/whatsapp.png')"
                alt=""
              />
              <span class="d-inline-block mx-2 font-18 font-500 white--text"
                >{{ $t("global.sendByWhatsapp") }}
              </span>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div
            class="radius-5 request-details__cart"
            :class="isMd ? 'pa-2' : 'pa-5'"
          >
            <div
              class="cart__item d-flex align-center justify-space-between mb-6"
              v-for="(item, i) in cartList"
              :key="i"
            >
              <div class="d-flex">
                <img :src="item.images[0] ? item.images[0].path : ''" alt="" />
                <div class="mx-3">
                  <span class="font-18 uppercase d-block cart-title">{{
                    item.name
                  }}</span>
                  <span class="font-18 uppercase d-block"
                    >{{ item.price }} TL</span
                  >

                  <div class="d-flex align-center">
                    <span
                      class="font-14 greyText--text d-inline-block"
                      v-for="(feature, i) in item.features"
                      :key="i"
                      >{{ feature }}
                      <span v-if="i < item.features.length - 1">,</span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <v-icon class="mb-3" @click="deleteItem(i)">mdi-close</v-icon>
                <base-counter
                  :value="item.count"
                  @changeCount="updateHandler($event, i)"
                ></base-counter>
              </div>
            </div>

            <div class="d-flex align-center justify-space-between mt-5">
              <v-text-field
                outlined
                v-model="coupon"
                hide-details
                :label="$t('global.useCoupon')"
                color="black"
              ></v-text-field>
              <v-btn
                class="ml-3"
                width="100px"
                color="primary"
                height="54px"
                elevation="0"
                @click="checkCoupon"
              >
                <span class="white--text font-18 uppercase">{{
                  $t("global.use")
                }}</span>
              </v-btn>
            </div>
            <div class="d-flex align-center justify-space-between mt-5">
              <span class="font-20 uppercase underline font-600"
                >{{ $t("global.total") }}:
              </span>
              <span
                class="font-20 font-600"
                :class="newPrice ? 'line-through' : ''"
                >{{ totalPrice.toFixed(2) }} TL</span
              >
            </div>
            <div
              class="d-flex align-center justify-space-between mt-5"
              v-if="newPrice"
            >
              <span class="font-18 uppercase underline font-600"
                >{{ $t("global.widthCoupon") }}:
              </span>
              <span class="font-20 primary--text font-600"
                >{{ newPrice.toFixed(2) }} TL</span
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    totalPrice: 0,
    phone: "",
    newPrice: 0,
    coupon: "",
    amountCoupon: 0,
    obj: { notes: "", toAddress: 0 },
  }),
  computed: {
    ...mapGetters(["renderCart", "cartList", "locationDetails", "settings"]),
    locationID() {
      return localStorage.getItem("locationID");
    },
    language() {
      return localStorage.getItem("language");
    },
  },
  watch: {
    renderCart: {
      handler() {
        this.checkTotalPrice();
      },
    },
  },
  methods: {
    deleteItem(i) {
      this.cartList.splice(i, 1);
      localStorage.setItem("cartList", JSON.stringify(this.cartList));
      this.$store.dispatch("setCartList", this.cartList);
      this.checkTotalPrice();
    },
    updateHandler($event, i) {
      this.cartList[i].count = $event;
      this.$store.dispatch("setCartList", this.cartList);
      this.checkTotalPrice();
    },
    checkTotalPrice() {
      this.totalPrice = 0;

      this.cartList.forEach((item) => {
        this.totalPrice += item.count * item.price;
      });
    },
    async checkCoupon() {
      if (!this.coupon) return;
      let res = await this.$axios.get(
        `frontend/check_coupon?location_id=${this.locationID}&coupon=${this.coupon}`
      );
      if (res.data) {
        if (res.data.min > this.totalPrice) {
          let neededPrice = res.data.min - this.totalPrice;
          this.$store.dispatch("showSnack", {
            text: `${this.$t("global.stillNeed")}  ${neededPrice} TL ${this.$t(
              "global.toUseCoupon"
            )} `,
            color: "error",
          });
          return;
        } else {
          if (res.data.is_amount) {
            this.newPrice = this.totalPrice - res.data.amount;
            this.amountCoupon = res.data.amount;
          } else {
            this.newPrice =
              this.totalPrice - (this.totalPrice * res.data.amount) / 100;
            this.amountCoupon = (this.totalPrice * res.data.amount) / 100;
          }
        }
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error.message.message,
          color: "error",
        });
      }
    },
    async sendRequest() {
      if (this.totalPrice < +this.settings[9].value) {
        this.$store.dispatch("showSnack", {
          text: this.$t("global.orderLimit") + this.settings[9].value,
          color: "error",
        });
        return;
      }
      if (!this.obj.username) {
        this.$store.dispatch("showSnack", {
          text: this.$t("global.nameRequire"),
          color: "error",
        });
        return;
      }
      let whatsappMessage = "";

      whatsappMessage += this.obj.username
        ? `${this.$t("global.username")} : ${this.obj.username}%0a`
        : "";
      whatsappMessage += this.obj.phone
        ? `${this.$t("global.userPhone")}: ${this.obj.phone}%0a`
        : "";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a`;

      this.cartList.forEach((item) => {
        whatsappMessage += `*${item.serial}* - ${item.name} ${item.count} * ${
          item.price
        } = ${item.count * item.price}  `;
        if (item.features && item.features.length > 0) {
          item.features.forEach((feature, i) => {
            whatsappMessage += ` (${feature})  ${
              i < item.features.length - 1 ? ", " : ""
            }`;
          });
        }
        whatsappMessage += `%0a`;
      });
      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a`;

      whatsappMessage += `*${this.$t("global.totalPrice")}*: ${
        this.newPrice ? `~${this.totalPrice}~` : this.totalPrice
      }  %0a`;
      // whatsappMessage += this.amountCoupon
      //   ? `${this.$t("global.couponAmount")}: ${this.amountCoupon}%0a`
      //   : "";
      whatsappMessage += this.newPrice
        ? `*${this.$t("global.generalTotal")}*: ${this.newPrice} %0a`
        : "";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a`;

      whatsappMessage += this.obj.notes
        ? `${this.$t("global.orderNotes")}: ${this.obj.notes}%0a`
        : "";
      if (this.obj.notes) {
        whatsappMessage += `${
          this.language == "ar"
            ? "ـــــــــــــــــــــــــــــ"
            : "---------------------"
        }`;
        whatsappMessage += `%0a`;
      }

      whatsappMessage += `${this.$t("global.toAddress")}: ${
        this.obj.toAddress ? "✓" : "✘"
      }%0a`;
      whatsappMessage += this.obj.address
        ? `${this.$t("global.userAddress")}: ${this.obj.address}%0a`
        : "";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a `;
      whatsappMessage += `image_link `;

      let req = {
        products: this.cartList.map((item) => {
          return {
            product_id: item.serial,
            name: item.name,
            price: item.price,
            amount: item.count,
            total: +item.count * +item.price,
          };
        }),
        is_delivery: this.obj.toAddress,
        general_total_price: this.totalPrice,
        coupon_value: this.amountCoupon,
        total_price: this.newPrice,
        products_amount: this.cartList.length,
      };
      this.$axios.defaults.baseURL = "https://api.master-chicken.com/v1/";
      let res = await this.$axios.get(
        "frontend/checkout",
        {
          params: {
            invoice_data: JSON.stringify(req),
            location_id: this.locationID,
            message: whatsappMessage,
            location_phone: this.locationDetails.phone,
            lang: this.language,
          },
        },
        {
          headers: {
            lang: this.language,
          },
        }
      );
      this.$axios.defaults.baseURL = "https://api.master-chicken.com/api/v1/";
      if (res.request.status == 200) {
        let userDetails = {
          username: this.obj.username,
          toAddress: this.obj.toAddress,
          address: this.obj.address,
          phone: this.obj.phone,
        };
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        this.$router.replace("/");
        window.open(res.request.responseURL, "_open");
        localStorage.setItem("cartList", JSON.stringify([]));
        this.$store.dispatch("renderCart");
        this.$store.dispatch("setCartList", []);
      }
    },
    async checkCartWithNewData() {
      let res = await this.$axios.get(
        `frontend/products_without_img?location_id=${this.locationID}`
      );
      if (res.data) {
        // check if price is changed
        this.cartList = this.cartList.forEach((cartProduct) => {
          res.data.forEach((product) => {
            if (cartProduct.id == product.id) {
              cartProduct.price = product.has_discount
                ? product.discountPrice
                : product.regularPrice;

              if (cartProduct.selectedFeaturePrice && cartProduct.selectedFeaturePrice.length > 0) {
                {
                  cartProduct.selectedFeaturePrice.forEach((featurePrice) => {
                    cartProduct.price = cartProduct.price + +featurePrice;
                  });
                }
              }
            }
          });
        });

        localStorage.setItem("cartList", JSON.stringify(this.cartList));
        this.$store.dispatch("renderCart");
        this.$store.dispatch("setCartList", this.cartList);
      }
    },
  },
  async mounted() {
    await this.checkCartWithNewData();

    let userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      this.obj = JSON.parse(userDetails);
    }
    setTimeout(async () => {
      await this.checkTotalPrice();
    }, 500);
  },
};
</script>

<style lang="scss">
.request-details {
  margin: 50px 0px;

  &__content {
    background: rgb(243 243 243 / 50%);
  }
  &__cart {
    background: rgb(243 243 243 / 50%);
  }

  .v-radio {
    padding: 12px;
    border: 1px solid gainsboro;
    width: 100%;
    border-radius: 5px;
  }

  .v-radio {
    margin: unset !important;
  }
}
.vue-tel-input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
}
</style>
